import React, { useEffect, useState } from "react";
import './index.css'
import { Link } from "@reach/router";
import axios from 'axios';
import post1 from './assets/images/posts/post-1.jpg'
import profile1 from './assets/images/profiles/profile-1.jpg'
import profile2 from './assets/images/profiles/profile-2.jpg'
import profile3 from './assets/images/profiles/profile-3.jpg'
import profile4 from './assets/images/profiles/profile-4.png'
import profile5 from './assets/images/profiles/profile-5.jpg'
import profile6 from './assets/images/profiles/profile-6.jpg'
import $, { data } from 'jquery';

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const put =async () =>{
    data={"username":$('#UserName').val(),"title":$('#Title').val(),"content":$('#Content').val()}
   const res= await axios.post('https://my-app.b1900624972.workers.dev/POST/posts', JSON.stringify(data),{
    headers:{
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
   })
    setPosts(res.data);
    window.location.reload();
    closemodel()


  }
  function showmodel(){
    $("#examplemodel").slideDown("slow");
    
  }
  function closemodel(){
    $("#examplemodel").slideUp("fast");
  }
  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        "https://my-app.b1900624972.workers.dev/GET/posts"
      );
      const postsResp = await resp.json();
      setPosts(JSON.parse(postsResp));
      
    };
    getPosts();
  }, []);


  return (
    <div>
       <div>
        <div>
          <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
            <div className="container justify-content-center">
              <div className="d-flex flex-row justify-content-between align-items-center col-9">
                <h2><a className="navbar-brand" href="#">
                  PicBook
                </a></h2>
                <div>
                  <form className="form-inline my-2 my-lg-0">
                    <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                  </form>
                </div>
                <div className="d-flex flex-row">
                  <ul className="list-inline m-0">
                    <li className="list-inline-item">
                      <a href="#" className="link-menu">
                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-house-door-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.5 10.995V14.5a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5V11c0-.25-.25-.5-.5-.5H7c-.25 0-.5.25-.5.495z" />
                          <path fillRule="evenodd" d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                        </svg>
                      </a>
                    </li>

                    <li className="list-inline-item ml-2">
                      <a href="#" className="link-menu">
                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-compass" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M8 15.016a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13zm0 1a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15z" />
                          <path d="M6 1a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2H7a1 1 0 0 1-1-1zm.94 6.44l4.95-2.83-2.83 4.95-4.95 2.83 2.83-4.95z" />
                        </svg>
                      </a>
                    </li>
                    <li className="list-inline-item ml-2">
                      <a href="#" className="link-menu">
                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-heart" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                        </svg>
                      </a>
                    </li>
                    <li className="list-inline-item ml-2 align-middle">
                      <a href="#" className="link-menu">
                        <div className="rounded-circle overflow-hidden d-flex justify-content-center align-items-center border topbar-profile-photo">
                          <img src={profile6} alt="..." style={{transform: 'scale(1.0)', width: '100%', position: 'absolute', left: 0}} />
                        </div>
                      </a>
                    </li>
                    <li className="list-inline-item" style={{marginLeft:'30px'}}>
                      <button onClick={showmodel} className="btn btn-light">
                        <h4><i className="fas fa-plus"></i> ADD Post</h4>
                      </button>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
          </nav>
        </div>
        <div style={{marginTop:'100px'}}>
          <div className="container d-flex justify-content-center">
            <div className="col-9">
              <div className="row">
                <div className="col-8">
                  <div className="card">
                    <div className="card-body d-flex justify-content-start">
                      <ul className="list-unstyled mb-0">
                      {posts.map((post) => ( 
                        <li className="list-inline-item">
                          <button className="btn p-0 m-0">
                            <div className="d-flex flex-column align-items-center">
                              <div className="rounded-circle overflow-hidden d-flex justify-content-center align-items-center border border-danger story-profile-photo">
                                <img src={profile1} alt="..." style={{transform: 'scale(1.0)', width: '100%', position: 'absolute', left: 0}} />
                              </div>
                              <small>{post.username}</small>
                            </div>
                          </button>
                        </li>
                        ))}
                        <li className="list-inline-item">
                          <button className="btn p-0 m-0">
                            <div className="d-flex flex-column align-items-center">
                              <div className="rounded-circle overflow-hidden d-flex justify-content-center align-items-center border border-danger story-profile-photo">
                                <img src={profile2} alt="..." style={{transform: 'scale(1.0)', width: '100%', position: 'absolute', left: 0}} />
                              </div>
                              <small>Diksha</small>
                            </div>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="d-flex flex-column mt-4 mb-4">
                  {posts.map((post) => (  
                  <div className="card mt-4">
                      <div className="card-header p-3">
                        <div className="d-flex flex-row align-items-center">
                          <div className="rounded-circle overflow-hidden d-flex justify-content-center align-items-center border border-danger post-profile-photo mr-3">
                            <img src={profile1} alt="..." style={{transform: 'scale(1.0)', width: '100%', position: 'absolute', left: 0}} />
                          </div>
                          <span className="font-weight-bold">{post.username}</span>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="embed-responsive embed-responsive-1by1">
                          <img className="embed-responsive-item" src={post1} />
                        </div>
                        <div className="d-flex flex-row justify-content-between pl-3 pr-3 pt-3 pb-1">
                          <ul className="list-inline d-flex flex-row align-items-center m-0">
                            <li className="list-inline-item">
                              <button className="btn p-0">
                                <svg width="1.6em" height="1.6em" viewBox="0 0 16 16" className="bi bi-heart" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                                </svg>
                              </button>
                            </li>
                            <li className="list-inline-item ml-2">
                              <button className="btn p-0">
                                <svg width="1.6em" height="1.6em" viewBox="0 0 16 16" className="bi bi-chat" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                                </svg>
                              </button>
                            </li>
                            <li className="list-inline-item ml-2">
                              <button className="btn p-0">
                                <svg width="1.6em" height="1.6em" viewBox="0 0 16 16" className="bi bi-share" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" d="M11.724 3.947l-7 3.5-.448-.894 7-3.5.448.894zm-.448 9l-7-3.5.448-.894 7 3.5-.448.894z" />
                                  <path fillRule="evenodd" d="M13.5 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm-11-6.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                                </svg>
                              </button>
                            </li>
                          </ul>
                          <div>
                            <button className="btn p-0">
                              <svg width="1.6em" height="1.6em" viewBox="0 0 16 16" className="bi bi-hdd" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M14 9H2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1zM2 8a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2H2z" />
                                <path d="M5 10.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                <path fillRule="evenodd" d="M4.094 4a.5.5 0 0 0-.44.26l-2.47 4.532A1.5 1.5 0 0 0 1 9.51v.99H0v-.99c0-.418.105-.83.305-1.197l2.472-4.531A1.5 1.5 0 0 1 4.094 3h7.812a1.5 1.5 0 0 1 1.317.782l2.472 4.53c.2.368.305.78.305 1.198v.99h-1v-.99a1.5 1.5 0 0 0-.183-.718L12.345 4.26a.5.5 0 0 0-.439-.26H4.094z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="pl-3 pr-3 pb-2">
                          <strong className="d-block">365.354 likes</strong>
                          <strong className="d-block">{post.title}</strong>
                          <p className="d-block mb-1">{post.content}</p>
                          <button className="btn p-0">
                            <span className="text-muted">View all 2,247 comments</span>
                          </button>
                          <div>
                            <div>
                              <strong className="d-block">Diksha</strong>
                              <span>❤️💓💓💓💓💓</span>
                            </div>
                            <div>
                              <strong className="d-block">Anusha</strong>
                              <span>Hi</span>
                            </div>
                          </div>
                          <small className="text-muted">4 HOURS AGO</small>
                        </div>
                        <div className="position-relative comment-box">
                          <form>
                            <input className="w-100 border-0 p-3 input-post" placeholder="Add a comment..." />
                            <button className="btn btn-primary position-absolute btn-ig">Post</button>
                          </form>
                        </div>
                      </div>
                    </div>
                    ))}
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex flex-row align-items-center">
                    <div className="rounded-circle overflow-hidden d-flex justify-content-center align-items-center border sidenav-profile-photo">
                      <img src={profile6} alt="..." style={{transform: 'scale(1.0)', width: '100%', position: 'absolute', left: 0}} />
                    </div>
                    <div className="profile-info ml-3">
                      <span className="profile-info-username">rishabh-stack</span>
                      <span className="profile-info-name">Rishabh Tenguria</span>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="d-flex flex-row justify-content-between">
                      <small className="text-muted font-weight-normal">Suggestions For You</small>
                      <small>See All</small>
                    </div>
                    <div>
                      <div className="d-flex flex-row justify-content-between align-items-center mt-3 mb-3">
                        <div className="d-flex flex-row align-items-center">
                          <div className="rounded-circle overflow-hidden d-flex justify-content-center align-items-center border sugest-profile-photo">
                            <img src={profile3} alt="..." style={{transform: 'scale(1.5)', width: '100%', position: 'absolute', left: 0}} />
                          </div>
                          <strong className="ml-3 sugest-username">instagram</strong>
                        </div>
                        <button className="btn btn-primary btn-sm p-0 btn-ig ">Follow</button>
                      </div>
                      <div className="d-flex flex-row justify-content-between align-items-center mt-3 mb-3">
                        <div className="d-flex flex-row align-items-center">
                          <div className="rounded-circle overflow-hidden d-flex justify-content-center align-items-center border sugest-profile-photo">
                            <img src={profile4} alt="..." style={{transform: 'scale(1.5)', width: '100%', position: 'absolute', left: 0}} />
                          </div>
                          <strong className="ml-3 sugest-username">dccomics</strong>
                        </div>
                        <button className="btn btn-primary btn-sm p-0 btn-ig ">Follow</button>
                      </div>
                      <div className="d-flex flex-row justify-content-between align-items-center mt-3 mb-3">
                        <div className="d-flex flex-row align-items-center">
                          <div className="rounded-circle overflow-hidden d-flex justify-content-center align-items-center border sugest-profile-photo">
                            <img src={profile5} alt="..." style={{transform: 'scale(1.5)', width: '100%', position: 'absolute', left: 0}} />
                          </div>
                          <strong className="ml-3 sugest-username">thecw</strong>
                        </div>
                        <button className="btn btn-primary btn-sm p-0 btn-ig">Follow</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" tabindex="-1" id="examplemodel">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">ADD New Post </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={closemodel}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
          <div class="row mb-4">
            <div class="col-6">
            <input type="text" name="UserName" id="UserName" class="form-control" placeholder="Username"/>
            </div>
            <div class="col-6">
            <input type="text" name="Title" id="Title" class="form-control" placeholder="Post Title"/>
            </div>
          </div>
            <div class="row mb-4">
            <div class="col-12">
              <input type="text" name="Content" id="Content" class="form-control" placeholder="Post Content"/>
            </div>
          </div>
         
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-mdb-dismiss="modal">
                  Close
                </button>
                <button onClick={put} id="Save" class="btn btn-primary">ADD POST</button>
        
              </div>
    </div>
    <div class="modal-footer">
  
    </div>
  </div>
</div>
</div>
    </div>
      
  );
};

export default Posts;
